import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Asset } from '@app/models';
import { ENV } from '@environments/environment.provider';
import { httpRetryCount } from '@environments/shared';
import { Observable, EMPTY, throwError, ReplaySubject } from 'rxjs';
import { catchError, filter, map, retry, switchMap, expand, reduce } from 'rxjs/operators';

interface PaginatedResponse<T> {
  items: T[];
  nextPage?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AssetApiHttpService {
  ASSETS_API_URL = this.env.coreEntityApiBase.url + '/assets';
  USERPROFILES_API_URL = this.env.coreEntityApiBase.url + '/userprofiles';
  APP_ID = this.env.auth.applicationId;

  private allAssets$: ReplaySubject<Asset[]> = new ReplaySubject<Asset[]>(1);

  constructor(@Inject(ENV) private env: any, private _httpService: HttpClient) {}

  getAssetsFromCache(): Observable<Asset[]> {
    return this.allAssets$.asObservable();
  }

  setAssetsToCache(assets: Asset[]): void {
    this.allAssets$.next(assets);
  }

  getAssets(companyId: string): Observable<Asset[]> {
    return this.getAllAssetsForCompany(companyId).pipe(
      map(assets => assets.filter(asset => asset.name.trim() !== '')), // Remove assets with blank or empty name
      map(assets => assets.sort((a, b) => a.name.localeCompare(b.name))) // Sort assets by name
    );
  }

  private getAllAssetsForCompany(companyId: string): Observable<Asset[]> {
    const assetUrl = `${this.ASSETS_API_URL}?companyId=${companyId}&filterFields=name,id&status=ACTIVE`;
    
    return this.getPaginatedData<Asset>(assetUrl, 1).pipe(
      expand(data => data.nextPage ? this.getPaginatedData<Asset>(assetUrl, data.nextPage) : EMPTY),
      reduce((acc, data) => acc.concat(data.items), [] as Asset[]),
      catchError(error => {
        console.error('Failed to get assets for company', error);
        return throwError(() => new Error('Failed to get assets for company'));
      })
    );
  }

  private getPaginatedData<T>(url: string, page: number, perPage: number = 1000): Observable<PaginatedResponse<T>> {
    const paginatedUrl = `${url}&per_page=${perPage}&page=${page}`;
    return this._httpService.get<T[]>(paginatedUrl).pipe(
      retry(httpRetryCount),
      map(items => {
        const hasNextPage = items.length === perPage;
        return {
          items,
          nextPage: hasNextPage ? page + 1 : undefined
        };
      })
    );
  }
}
